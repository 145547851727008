/**
 *
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventHandler, Internationalization } from '@syncfusion/ej2-base';
import { rippleEffect } from '@syncfusion/ej2-base';
import { removeClass, addClass, attributes, HijriParser } from '@syncfusion/ej2-base';
import { getUniqueID } from '@syncfusion/ej2-base';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
//class constant defination.
const OTHERMONTH = 'e-other-month';
const YEAR = 'e-year';
const MONTH = 'e-month';
const DECADE = 'e-decade';
const DISABLED = 'e-disabled';
const OVERLAY = 'e-overlay';
const WEEKEND = 'e-weekend';
const WEEKNUMBER = 'e-week-number';
const SELECTED = 'e-selected';
const FOCUSEDDATE = 'e-focused-date';
const OTHERMONTHROW = 'e-month-hide';
const TODAY = 'e-today';
const LINK = 'e-day';
const CELL = 'e-cell';
const dayMilliSeconds = 86400000;
const minDecade = 2060;
const maxDecade = 2069;
export class Islamic {
    constructor(instance) {
        this.calendarInstance = instance;
    }
    getModuleName() {
        return 'islamic';
    }
    islamicTitleUpdate(date, view) {
        const globalize = new Internationalization(this.calendarInstance.locale);
        switch (view) {
            case 'days':
                this.calendarInstance.headerTitleElement.textContent = globalize.formatDate(date, { type: 'dateTime', format: 'MMMMyyyy', calendar: 'islamic' });
                break;
            case 'months':
                this.calendarInstance.headerTitleElement.textContent = globalize.formatDate(date, { type: 'dateTime', format: 'yyyy', calendar: 'islamic' });
        }
    }
    islamicRenderDays(currentDate, value, multiSelection, values) {
        const tdEles = [];
        const cellsCount = 42;
        let localDate = new Date(this.islamicInValue(currentDate));
        let minMaxDate;
        this.islamicTitleUpdate(currentDate, 'days');
        const islamicDate = this.getIslamicDate(localDate);
        const gregorianObject = this.toGregorian(islamicDate.year, islamicDate.month, 1);
        const currentMonth = islamicDate.month;
        localDate = gregorianObject;
        while (localDate.getDay() !== this.calendarInstance.firstDayOfWeek) {
            this.calendarInstance.setStartDate(localDate, -1 * dayMilliSeconds);
        }
        for (let day = 0; day < cellsCount; ++day) {
            const weekEle = this.calendarInstance.createElement('td', { className: CELL });
            const weekAnchor = this.calendarInstance.createElement('span');
            if (day % 7 === 0 && this.calendarInstance.weekNumber) {
                weekAnchor.textContent = '' + this.calendarInstance.getWeek(localDate);
                weekEle.appendChild(weekAnchor);
                addClass([weekEle], '' + WEEKNUMBER);
                tdEles.push(weekEle);
            }
            minMaxDate = new Date(+localDate);
            localDate = this.calendarInstance.minMaxDate(localDate);
            const dateFormatOptions = { type: 'dateTime', skeleton: 'full', calendar: 'islamic' };
            const date = this.calendarInstance.globalize.parseDate(this.calendarInstance.globalize.formatDate(localDate, dateFormatOptions), dateFormatOptions);
            const tdEle = this.islamicDayCell(localDate);
            const title = this.calendarInstance.globalize.formatDate(localDate, { type: 'date', skeleton: 'full', calendar: 'islamic' });
            const dayLink = this.calendarInstance.createElement('span');
            dayLink.textContent = this.calendarInstance.globalize.formatDate(localDate, { type: 'date', skeleton: 'd', calendar: 'islamic' });
            const disabled = (this.calendarInstance.min > localDate) || (this.calendarInstance.max < localDate);
            if (disabled) {
                addClass([tdEle], DISABLED);
                addClass([tdEle], OVERLAY);
            }
            else {
                dayLink.setAttribute('title', '' + title);
            }
            const hijriMonthObject = this.getIslamicDate(localDate);
            if (currentMonth !== hijriMonthObject.month) {
                addClass([tdEle], OTHERMONTH);
            }
            if (localDate.getDay() === 0 || localDate.getDay() === 6) {
                addClass([tdEle], WEEKEND);
            }
            tdEle.appendChild(dayLink);
            this.calendarInstance.renderDayCellArgs = {
                date: localDate,
                isDisabled: false,
                element: tdEle,
                isOutOfRange: disabled
            };
            const argument = this.calendarInstance.renderDayCellArgs;
            this.calendarInstance.renderDayCellEvent(argument);
            if (argument.isDisabled) {
                if (this.calendarInstance.isMultiSelection) {
                    if (!isNullOrUndefined(this.calendarInstance.values) && this.calendarInstance.values.length > 0) {
                        for (let index = 0; index < values.length; index++) {
                            const localDateString = +new Date(this.calendarInstance.globalize.formatDate(argument.date, { type: 'date', skeleton: 'yMd', calendar: 'islamic' }));
                            const tempDateString = +new Date(this.calendarInstance.globalize.formatDate(this.calendarInstance.values[index], { type: 'date', skeleton: 'yMd', calendar: 'islamic' }));
                            if (localDateString === tempDateString) {
                                this.calendarInstance.values.splice(index, 1);
                                index = -1;
                            }
                        }
                    }
                }
                else if (value && +value === +argument.date) {
                    this.calendarInstance.setProperties({ value: null }, true);
                }
            }
            if (this.calendarInstance.renderDayCellArgs.isDisabled && !tdEle.classList.contains(SELECTED)) {
                addClass([tdEle], DISABLED);
                addClass([tdEle], OVERLAY);
                if (+this.calendarInstance.renderDayCellArgs.date === +this.calendarInstance.todayDate) {
                    this.calendarInstance.todayDisabled = true;
                }
            }
            const otherMnthBool = tdEle.classList.contains(OTHERMONTH);
            const disabledCls = tdEle.classList.contains(DISABLED);
            if (!disabledCls) {
                EventHandler.add(tdEle, 'click', this.calendarInstance.clickHandler, this.calendarInstance);
            }
            if (this.calendarInstance.isMultiSelection && !isNullOrUndefined(this.calendarInstance.values) &&
                !otherMnthBool && !disabledCls) {
                for (let tempValue = 0; tempValue < this.calendarInstance.values.length; tempValue++) {
                    const localDateString = this.calendarInstance.globalize.formatDate(localDate, { type: 'date', skeleton: 'short', calendar: 'islamic' });
                    const tempDateString = this.calendarInstance.globalize.formatDate(this.calendarInstance.values[tempValue], { type: 'date', skeleton: 'short', calendar: 'islamic' });
                    if (localDateString === tempDateString &&
                        this.calendarInstance.getDateVal(localDate, this.calendarInstance.values[tempValue])) {
                        addClass([tdEle], SELECTED);
                    }
                    else {
                        this.calendarInstance.updateFocus(otherMnthBool, disabledCls, localDate, tdEle, currentDate);
                    }
                }
                if (this.calendarInstance.values.length <= 0) {
                    this.calendarInstance.updateFocus(otherMnthBool, disabledCls, localDate, tdEle, currentDate);
                }
            }
            else if (!otherMnthBool && !disabledCls && this.calendarInstance.getDateVal(localDate, value)) {
                addClass([tdEle], SELECTED);
            }
            else {
                this.calendarInstance.updateFocus(otherMnthBool, disabledCls, localDate, tdEle, currentDate);
            }
            if (date.getDate() === new Date().getDate() && date.getMonth() === new Date().getMonth()) {
                if (date.getFullYear() === new Date().getFullYear()) {
                    addClass([tdEle], TODAY);
                }
            }
            localDate = new Date(+minMaxDate);
            tdEles.push(this.calendarInstance.renderDayCellArgs.element);
            this.calendarInstance.addDay(localDate, 1, null, this.calendarInstance.max, this.calendarInstance.min);
        }
        return tdEles;
    }
    islamicIconHandler() {
        new Date(this.islamicInValue(this.calendarInstance.currentDate)).setDate(1);
        const date = new Date(this.islamicInValue(this.calendarInstance.currentDate));
        switch (this.calendarInstance.currentView()) {
            case 'Month':
                {
                    const prevMonthCompare = this.islamicCompareMonth(date, this.calendarInstance.min) < 1;
                    const nextMonthCompare = this.islamicCompareMonth(date, this.calendarInstance.max) > -1;
                    this.calendarInstance.previousIconHandler(prevMonthCompare);
                    this.calendarInstance.nextIconHandler(nextMonthCompare);
                }
                break;
            case 'Year':
                {
                    const prevYearCompare = this.hijriCompareYear(date, this.calendarInstance.min) < 1;
                    const nextYearCompare = this.hijriCompareYear(date, this.calendarInstance.max) > -1;
                    this.calendarInstance.previousIconHandler(prevYearCompare);
                    this.calendarInstance.nextIconHandler(nextYearCompare);
                }
                break;
            case 'Decade': {
                const prevDecadeCompare = this.hijriCompareDecade(date, this.calendarInstance.min) < 1;
                const nextDecadeCompare = this.hijriCompareDecade(date, this.calendarInstance.max) > -1;
                this.calendarInstance.previousIconHandler(prevDecadeCompare);
                this.calendarInstance.nextIconHandler(nextDecadeCompare);
            }
        }
    }
    islamicNext() {
        this.calendarInstance.effect = '';
        const view = this.calendarInstance.getViewNumber(this.calendarInstance.currentView());
        const islamicDate = this.getIslamicDate(this.calendarInstance.currentDate);
        switch (this.calendarInstance.currentView()) {
            case 'Year':
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year + 1, islamicDate.month, 1);
                this.calendarInstance.switchView(view);
                break;
            case 'Month':
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year, islamicDate.month + 1, 1);
                this.calendarInstance.switchView(view);
                break;
            case 'Decade':
                this.calendarInstance.nextIconClicked = true;
                islamicDate.year = islamicDate.year - this.calendarInstance.headerElement.textContent.split('-')[0].trim() === 1 ? islamicDate.year + 1 : islamicDate.year;
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year + 10, islamicDate.month, 1);
                this.calendarInstance.switchView(view);
                break;
        }
    }
    islamicPrevious() {
        const currentView = this.calendarInstance.getViewNumber(this.calendarInstance.currentView());
        this.calendarInstance.effect = '';
        const islamicDate = this.getIslamicDate(this.calendarInstance.currentDate);
        switch (this.calendarInstance.currentView()) {
            case 'Month':
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year, islamicDate.month - 1, 1);
                this.calendarInstance.switchView(currentView);
                break;
            case 'Year':
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year - 1, islamicDate.month, 1);
                this.calendarInstance.switchView(currentView);
                break;
            case 'Decade':
                this.calendarInstance.previousIconClicked = true;
                this.calendarInstance.currentDate = this.toGregorian(islamicDate.year - 10, islamicDate.month - 1, 1);
                this.calendarInstance.switchView(currentView);
                break;
        }
    }
    islamicRenderYears(e, value) {
        this.calendarInstance.removeTableHeadElement();
        const numCells = 4;
        const tdEles = [];
        const valueUtil = isNullOrUndefined(value);
        const curDate = new Date(this.islamicInValue(this.calendarInstance.currentDate));
        let localDate = curDate;
        const islamicDate = this.getIslamicDate(localDate);
        const gregorianObject = HijriParser.toGregorian(islamicDate.year, 1, 1);
        localDate = gregorianObject;
        const mon = islamicDate.month;
        const yr = islamicDate.year;
        const curYrs = islamicDate.year;
        const minYr = (this.getIslamicDate(this.calendarInstance.min)).year;
        const minMonth = (this.getIslamicDate(this.calendarInstance.min)).month;
        const maxYr = (this.getIslamicDate(this.calendarInstance.max)).year;
        const maxMonth = (this.getIslamicDate(this.calendarInstance.max)).month;
        this.islamicTitleUpdate(this.calendarInstance.currentDate, 'months');
        for (let month = 1; month <= 12; ++month) {
            const islamicDate = this.getIslamicDate(localDate);
            const gregorianObject = HijriParser.toGregorian(islamicDate.year, month, 1);
            localDate = gregorianObject;
            const tdEle = this.islamicDayCell(localDate);
            const dayLink = this.calendarInstance.createElement('span');
            const localMonth = (value &&
                (this.getIslamicDate(value)).month === (this.getIslamicDate(localDate)).month);
            const select = (value && (this.getIslamicDate(value)).year === yr && localMonth);
            dayLink.textContent = this.calendarInstance.globalize.formatDate(localDate, { type: 'dateTime', format: 'MMM', calendar: 'islamic' });
            if ((this.calendarInstance.min && (curYrs < minYr || (month < minMonth && curYrs === minYr))) || (this.calendarInstance.max && (curYrs > maxYr || (month > maxMonth && curYrs >= maxYr)))) {
                addClass([tdEle], DISABLED);
            }
            else if (!valueUtil && select) {
                addClass([tdEle], SELECTED);
            }
            else {
                if ((this.getIslamicDate(localDate)).month === mon &&
                    (this.getIslamicDate(this.calendarInstance.currentDate)).month === mon) {
                    addClass([tdEle], FOCUSEDDATE);
                }
            }
            if (!tdEle.classList.contains(DISABLED)) {
                EventHandler.add(tdEle, 'click', this.calendarInstance.clickHandler, this.calendarInstance);
            }
            tdEle.appendChild(dayLink);
            tdEles.push(tdEle);
        }
        this.islamicRenderTemplate(tdEles, numCells, YEAR, e, value);
    }
    islamicRenderDecade(e, value) {
        this.calendarInstance.removeTableHeadElement();
        const numCells = 4;
        const yearCell = 12;
        const tdEles = [];
        let localDate = new Date(this.islamicInValue(this.calendarInstance.currentDate));
        let islamicDate = this.getIslamicDate(localDate);
        const gregorianObject = HijriParser.toGregorian(islamicDate.year, 1, 1);
        localDate = gregorianObject;
        const localYr = localDate.getFullYear();
        const startYr = new Date(this.islamicInValue((localYr - localYr % 10)));
        const endYr = new Date(this.islamicInValue((localYr - localYr % 10 + (10 - 1))));
        let startFullYr = startYr.getFullYear();
        let endFullYr = endYr.getFullYear();
        let startHdrYr = this.calendarInstance.globalize.formatDate(startYr, { type: 'dateTime', format: 'y', calendar: 'islamic' });
        let endHdrYr = this.calendarInstance.globalize.formatDate(endYr, { type: 'dateTime', format: 'y', calendar: 'islamic' });
        if (this.calendarInstance.islamicPreviousHeader) {
            startHdrYr = this.calendarInstance.islamicPreviousHeader.split('-')[0].trim();
            endHdrYr = this.calendarInstance.islamicPreviousHeader.split('-')[1].trim();
            this.calendarInstance.islamicPreviousHeader = null;
        }
        let splityear = this.calendarInstance.headerElement.textContent.split('-');
        if (this.calendarInstance.previousIconClicked) {
            for (var i = 0; i <= splityear.length; i++) {
                endHdrYr = endHdrYr - splityear[i] === 2 || splityear[i] - endHdrYr === 2 ? (parseInt(endHdrYr) + 1).toString() : endHdrYr - splityear[i] === 3 || splityear[i] - endHdrYr === 3 ? (parseInt(endHdrYr) + 2).toString() : endHdrYr - splityear[i] === 4 || splityear[i] - endHdrYr === 4 ? (parseInt(endHdrYr) + 3).toString() : endHdrYr - splityear[i] === 5 || splityear[i] - endHdrYr === 5 ? (parseInt(endHdrYr) + 4).toString() : endHdrYr;
                if (endHdrYr - splityear[i] === 0 || splityear[i] - endHdrYr === 0) {
                    endHdrYr = (parseInt(endHdrYr) - 1).toString();
                }
            }
            startHdrYr = endHdrYr - startHdrYr === 10
                ? (parseInt(startHdrYr) + 1).toString() : endHdrYr - startHdrYr === 11
                ? (parseInt(startHdrYr) + 2).toString() : endHdrYr - startHdrYr === 12
                ? (parseInt(startHdrYr) + 3).toString() : startHdrYr;
            startHdrYr = endHdrYr - startHdrYr === 8 ? (parseInt(startHdrYr) - 1).toString() : startHdrYr;
        }
        if (this.calendarInstance.nextIconClicked) {
            for (var i = 0; i <= splityear.length; i++) {
                if (startHdrYr - splityear[i] === 0 || splityear[i] - startHdrYr === 0) {
                    startHdrYr = (parseInt(startHdrYr) + 1).toString();
                }
                if (startHdrYr - splityear[i] === 2 && startHdrYr > splityear[i].trim()) {
                    startHdrYr = (parseInt(startHdrYr) - 1).toString();
                }
                if (splityear[i] - startHdrYr === 1 && startHdrYr < splityear[i].trim()) {
                    startHdrYr = (parseInt(startHdrYr) + 2).toString();
                }
            }
            endHdrYr = endHdrYr - startHdrYr === 10 ? (parseInt(endHdrYr) - 1).toString() : endHdrYr;
            endHdrYr = endHdrYr - startHdrYr === 7
                ? (parseInt(endHdrYr) + 2).toString() : endHdrYr - startHdrYr === 8
                ? (parseInt(endHdrYr) + 1).toString() : endHdrYr;
        }
        this.calendarInstance.headerTitleElement.textContent = startHdrYr + ' - ' + (endHdrYr);
        this.calendarInstance.nextIconClicked = this.calendarInstance.previousIconClicked = false;
        const year = (parseInt(startHdrYr) - 2).toString();
        startFullYr = Math.round(parseInt(startHdrYr) * 0.97 + 622);
        endFullYr = Math.round(parseInt(endHdrYr) * 0.97 + 622);
        let startYear = Math.round(parseInt(year) * 0.97 + 622);
        for (let rowCount = 1; rowCount <= yearCell; ++rowCount) {
            const year = startYear + rowCount;
            localDate.setFullYear(year);
            localDate.setDate(1);
            localDate.setMonth(0);
            if ((this.getIslamicDate(localDate).year - islamicDate.year) > 1) {
                localDate.setMonth(1);
                rowCount = rowCount - 1;
                localDate.setFullYear(localDate.getFullYear() - 1);
            }
            islamicDate = this.getIslamicDate(localDate);
            const gregorianObject = HijriParser.toGregorian(islamicDate.year, 1, 1);
            localDate = gregorianObject;
            if (islamicDate.year === parseInt(startHdrYr) - 1 || islamicDate.year >= startHdrYr && islamicDate.year <= endFullYr || islamicDate.year === parseInt(endHdrYr) + 1) {
                const tdEle = this.islamicDayCell(localDate);
                attributes(tdEle, { 'role': 'gridcell' });
                const dayLink = this.calendarInstance.createElement('span');
                dayLink.textContent = this.calendarInstance.globalize.formatDate(localDate, { type: 'dateTime', format: 'y', calendar: 'islamic' });
                if (islamicDate.year === parseInt(startHdrYr) - 1 || (year < startFullYr) || (year > endFullYr) && islamicDate.year !== parseInt(endHdrYr)) {
                    addClass([tdEle], OTHERMONTH);
                    if (year < new Date(this.islamicInValue(this.calendarInstance.min)).getFullYear()
                        || year > new Date(this.islamicInValue(this.calendarInstance.max)).getFullYear()) {
                        addClass([tdEle], DISABLED);
                    }
                }
                else if (year < new Date(this.islamicInValue(this.calendarInstance.min)).getFullYear()
                    || year > new Date(this.islamicInValue(this.calendarInstance.max)).getFullYear()) {
                    addClass([tdEle], DISABLED);
                }
                else if (!isNullOrUndefined(value) &&
                    (this.getIslamicDate(localDate)).year ===
                        (this.getIslamicDate(value)).year) {
                    addClass([tdEle], SELECTED);
                }
                else {
                    if (localDate.getFullYear() === this.calendarInstance.currentDate.getFullYear() && !tdEle.classList.contains(DISABLED)) {
                        addClass([tdEle], FOCUSEDDATE);
                    }
                }
                if (!tdEle.classList.contains(DISABLED)) {
                    EventHandler.add(tdEle, 'click', this.calendarInstance.clickHandler, this.calendarInstance);
                }
                tdEle.appendChild(dayLink);
                tdEles.push(tdEle);
            }
        }
        this.islamicRenderTemplate(tdEles, numCells, 'e-decade', e, value);
    }
    islamicDayCell(localDate) {
        const dateFormatOptions = { skeleton: 'full', type: 'dateTime', calendar: 'islamic' };
        const formatDate = this.calendarInstance.globalize.formatDate(localDate, dateFormatOptions);
        const date = this.calendarInstance.globalize.parseDate(formatDate, dateFormatOptions);
        const value = date.valueOf();
        const attrs = {
            className: CELL, attrs: { 'id': '' + getUniqueID('' + value), 'aria-selected': 'false', 'role': 'gridcell' }
        };
        return this.calendarInstance.createElement('td', attrs);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    islamicRenderTemplate(elements, count, classNm, e, value) {
        const view = this.calendarInstance.getViewNumber(this.calendarInstance.currentView());
        let trEle;
        this.calendarInstance.tableBodyElement = this.calendarInstance.createElement('tbody');
        this.calendarInstance.table.appendChild(this.calendarInstance.tableBodyElement);
        removeClass([this.calendarInstance.contentElement, this.calendarInstance.headerElement], [MONTH, DECADE, YEAR]);
        addClass([this.calendarInstance.contentElement, this.calendarInstance.headerElement], [classNm]);
        const weekNumCell = 41;
        const numberCell = 35;
        const otherMonthCell = 6;
        let row = count;
        let rowCount = 0;
        for (let dayCell = 0; dayCell < Math.round(elements.length / count); ++dayCell) {
            trEle = this.calendarInstance.createElement('tr', { attrs: { 'role': 'row' } });
            for (rowCount = 0 + rowCount; rowCount < row; rowCount++) {
                if (!elements[rowCount].classList.contains('e-week-number') && !isNullOrUndefined(elements[rowCount].children[0])) {
                    addClass([elements[rowCount].children[0]], [LINK]);
                    rippleEffect(elements[rowCount].children[0], {
                        duration: 600,
                        isCenterRipple: true
                    });
                }
                trEle.appendChild(elements[rowCount]);
                if (this.calendarInstance.weekNumber &&
                    rowCount === otherMonthCell + 1 && elements[otherMonthCell + 1].classList.contains(OTHERMONTH)) {
                    addClass([trEle], OTHERMONTHROW);
                }
                if (!this.calendarInstance.weekNumber
                    && rowCount === otherMonthCell && elements[otherMonthCell].classList.contains(OTHERMONTH)) {
                    addClass([trEle], OTHERMONTHROW);
                }
                if (this.calendarInstance.weekNumber) {
                    if (rowCount === weekNumCell && elements[weekNumCell].classList.contains(OTHERMONTH)) {
                        addClass([trEle], OTHERMONTHROW);
                    }
                }
                else {
                    if (rowCount === numberCell && elements[numberCell].classList.contains(OTHERMONTH)) {
                        addClass([trEle], OTHERMONTHROW);
                    }
                }
            }
            row = row + count;
            rowCount = rowCount + 0;
            this.calendarInstance.tableBodyElement.appendChild(trEle);
        }
        this.calendarInstance.table.querySelector('tbody').className = this.calendarInstance.effect;
        this.islamicIconHandler();
        if (view !== this.calendarInstance.getViewNumber(this.calendarInstance.currentView())
            || (view === 0 && view !== this.calendarInstance.getViewNumber(this.calendarInstance.currentView()))) {
            this.calendarInstance.navigateHandler(e);
        }
        this.calendarInstance.setAriaActiveDescendant();
        this.calendarInstance.changedArgs = { value: this.calendarInstance.value, values: this.calendarInstance.values };
        this.calendarInstance.changeHandler();
    }
    islamicCompareMonth(start, end) {
        const hijriStart = (this.getIslamicDate(start));
        const hijriEnd = (this.getIslamicDate(end));
        let result;
        if (hijriStart.year > hijriEnd.year) {
            result = 1;
        }
        else if (hijriStart.year < hijriEnd.year) {
            result = -1;
        }
        else {
            result = hijriStart.month === hijriEnd.month ? 0 : hijriStart.month > hijriEnd.month ? 1 : -1;
        }
        return result;
    }
    islamicCompare(startDate, endDate, modifier) {
        const hijriStart = this.getIslamicDate(startDate);
        const hijriEnd = this.getIslamicDate(endDate);
        let start = hijriEnd.year;
        let end;
        let result;
        end = start;
        result = 0;
        if (modifier) {
            start = start - start % modifier;
            end = start - start % modifier + modifier - 1;
        }
        if (hijriStart.year > end) {
            result = 1;
        }
        else if ((this.calendarInstance.currentView() === 'Decade') && hijriStart.year < start &&
            !((startDate.getFullYear() >= minDecade && startDate.getFullYear() <= maxDecade))) {
            result = -1;
        }
        else if (hijriStart.year < start && (this.calendarInstance.currentView() === 'Year')) {
            result = -1;
        }
        return result;
    }
    getIslamicDate(date) {
        return (HijriParser.getHijriDate(date));
    }
    toGregorian(year, month, date) {
        return HijriParser.toGregorian(year, month, date);
    }
    hijriCompareYear(start, end) {
        return this.islamicCompare(start, end, 0);
    }
    hijriCompareDecade(start, end) {
        return this.islamicCompare(start, end, 10);
    }
    destroy() {
        this.calendarInstance = null;
    }
    islamicInValue(inValue) {
        if (inValue instanceof Date) {
            return (inValue.toUTCString());
        }
        else {
            return ('' + inValue);
        }
    }
}
/* eslint-enable @typescript-eslint/no-explicit-any */
